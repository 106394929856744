import * as React from 'react'
import Footer from '../components/sections/Footer'
import { Link } from 'gatsby'
import Navbar from '../components/sections/Navbar'
import Seo from '../components/Seo'

/* assets */
import image from '../assets/images/404_donuts.svg'

const NotFoundPage = () => {
  return (
    <>
      <Seo title='Sorry, page not found' />
      <header>
        <Navbar showProgress />
      </header>
      <main id='main' className='container text-center relative mt-navbar md:mt-navbarDesktop'>
        <div className='pt-4 pb-8'>
          <h1 className='text-center text-secondary mt-12'>Danger Will Robinson!</h1>
          <h2 className='text-center'> - unable to locate entity -</h2>
        </div>
        <img src={image} title='quirky 404 donut image' alt='' className='h-20vh mx-auto' />
        <p className='mt-8 mb-0'>
          Sorry{' '}
          <span role='img' aria-label='Pensive emoji'>
            😔
          </span>{' '}
          we couldn’t find what you were looking for, have a donut from our tree instead.
        </p>
        {process.env.NODE_ENV === 'development' && (
          <p>
            Try creating a page in <code>src/pages/</code>.
          </p>
        )}
        <Link to='/' className='text-lg'>
          click here to return home
        </Link>
      </main>
      <Footer />
    </>
  )
}

export default NotFoundPage
